<template>
  <el-dialog
    :title="title"
    :visible.sync="DialogVisible"
    width="45%"
    :close-on-click-modal="false"
    @close="DialogClosed"
  >
    <el-row
      :gutter="30"
      style="text-align: left; margin-left: 10px; margin-right: 10px"
    >
      <div style="display: flex">
        <div>
          <p>
            <span style="margin-right: 20px"
              >订单/工单编号：{{ detailInfo.tradeNo }}</span
            >
            <span
              :style="detailInfo.status === 1 ? 'color:#67C23A' : 'color:red'"
              >{{ detailInfo.status === 1 ? "已完成" : "处理中" }}</span
            >
          </p>
          <p>
            <span>付款账号：{{ detailInfo.payAccount }}</span>
          </p>
          <p>
            <span>交易金额(元)：{{ detailInfo.tradeAmount }}</span>
          </p>

          <p>
            <span>用户名：{{ detailInfo.username }}</span>
          </p>
          <p>
            <span>公司：{{ detailInfo.company }}</span>
          </p>
          <p>
            <span>地域：{{ detailInfo.area }}</span>
          </p>
        </div>
        <div style="display: inline-block; margin-left: 100px">
          <p>
            <span>流水号：{{ detailInfo.transactionNo }}</span>
          </p>
          <p>
            <span>收款账号：{{ detailInfo.receiptAccount }}</span>
          </p>
          <p>
            <span>交易时间：{{ detailInfo.createTime }}</span>
          </p>
          <p>
            <span>邮箱：{{ detailInfo.email }}</span>
          </p>
          <p>
            <span>职位：{{ detailInfo.position }}</span>
          </p>
        </div>
      </div>
    </el-row>

    <el-row
      :gutter="30"
      style="display: flex; margin-right: 1px; margin-left: 1px"
    >
      <el-table
        :data="orderDetailList"
        :header-cell-style="{
          backgroundColor: 'rgb(250, 250, 250)',
          'text-align': 'center',
        }"
        tooltip-effect="dark"
      >
        <el-table-column
          prop="name"
          label="产品名称"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="资源类型" align="center" prop="itemType">
        </el-table-column>
        <el-table-column prop="price" label="价格" align="center">
        </el-table-column>
        <el-table-column
          prop="ownerId"
          label="作者ID"
          align="center"
        ></el-table-column>
        <el-table-column prop="ownerName" label="作者" align="center">
        </el-table-column>
      </el-table>
    </el-row>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '明细详情'
    },
    detailInfo: {
      type: Object
    },
    orderDetailList: {
      type: Array
    },
  },
  computed: {
    DialogVisible: {
      get () {
        return this.visible
      },
      set () {
      }
    }
  },
  methods: {
    DialogClosed () {
      this.$emit('closeDialog')
    },
  }
}
</script>